import React from "react";
import Faq from "./faq/faq";
import "./../index.css";
import { useNavigate } from "react-router-dom";

function Page() {
  const navigate = useNavigate();

  function GoToRsvpForm() {
    navigate("/form");
  }

  return (
    <>
      <div className="container-fluid overflow-hidden">
        <div className="row fill-page">
          <div className="col-lg pbg-brown">
            <div className="row half-section">
              <div className="row title-top-pad">
                <div className="page-font title">MARIA<br />&<br />SANDER</div>
              </div>
              <div className="row ms-2">
                <div className="page-font location">
                  PILGUSE MÕIS 
                  <span className="ms-3 me-3">|</span>
                  LÜGANUSE, SAAREMAA
                  <span className="ms-3 me-3">|</span>
                  08.07.23 KELL 17:00
                </div>
              </div>
              <div className="row d-flex align-items-center justify-content-center">
                  <div className="rsvp-button" onClick={GoToRsvpForm}>
                    <span className="align-middle page-font rsvp">TULEN / EI TULE</span>
                </div>
              </div>
            </div>
            <div className="row half-section h-50 flowers-image-bg">
            </div>
          </div>
          <div className="col-lg pbg-green">
            <Faq></Faq>
          </div>
        </div>
        </div>
    </>
  );
}

export default Page;
