import "./seeu.css";
import SeeUImage from './../../images/seeu.jpg';
import { useNavigate } from "react-router-dom";

function SeeU() {

    const navigate = useNavigate();

    const backHome = () => {
        navigate('/');
    }

    return(
        <>
        <div className="container-fluid overflow-hidden">
            <div className="row pt-5 page">
                <div className="col margin pt-5">
                    <div className="row">
                        <div className="col-12 pb-3">
                            <span className="back-link" onClick={backHome}>TAGASI AVALEHELE</span>
                        </div>
                        <div className="col-12">
                            <img className="image" src={SeeUImage} alt={"Kohtumiseni Pilgusel"}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default SeeU;