import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Bummer from './components/bummer/bummer';
import Form from './components/form/form';
import NotFound from './components/notfound/notfound';
import Page from './components/page';
import Report from './components/report/report';
import SeeU from './components/seeu/seeu';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Page />} />
        <Route path="/form" element={<Form />} />
        <Route path="/bummer" element={<Bummer />} />
        <Route path="/seeu" element={<SeeU />} />       
        <Route path="/report" element={<Report />} />
        <Route path='/dang' element={<NotFound />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
