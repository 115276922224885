
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { IRegFormData } from '../../interfaces/interfaces';
import "./form.css";

function Form() {
    
    const navigate = useNavigate();
    const [regFormData, setFormData] = useState<IRegFormData>({
        primaryGuest: '',
        pgIsComing: '',
        pgDays: '',
        sgIsComing: '',
        secondaryGuest: '',
        sgDays: '',
        comments: ''
    });
    const [formError, setFormError] = useState<string>('');

    const handleInputChange = (e: any) => {
        let { name, value } = e.target;
        if(name === 'pgIsComing' && value === 'no') {
            regFormData.sgIsComing = '';
        }
        setFormData({...regFormData, [name]:value});
        setFormError('');
    };

    const primaryGuestForm = () => {
        return (
            <>
            <div className="mb-5">
                <label htmlFor="primaryGuest" className="form-label">SINU NIMI:</label>
                <input type="text" className="form-control" id="primaryGuest" name="primaryGuest" placeholder='Sisesta siia oma nimi' value={regFormData.primaryGuest} onChange={handleInputChange}></input>
            </div>
            <div className="row mb-5">
                <div className='col-6'>
                    <label htmlFor="pgIsComing" className="form-label">KAS TULED PULMAPEOLE?</label>
                </div>
                <div className='col-2'>
                    <input className="form-check-input me-2" type="radio" name="pgIsComing" id="pgIsComing1" value="yes" onChange={handleInputChange}></input>
                    <label className="form-check-label" htmlFor="pgIsComing1">JAH</label>
                </div>
                <div className='col-2'>
                    <input className="form-check-input me-2" type="radio" name="pgIsComing" id="pgIsComing2" value="no" onChange={handleInputChange}></input>
                    <label className="form-check-label" htmlFor="pgIsComing2">EI</label>
                </div>
            </div>
            { regFormData.pgIsComing === 'yes' && kuupaevaVorm('pg') }
            { regFormData.pgIsComing === 'yes' && 
            <div className="row">
                <div className='col-6'>
                    <label htmlFor="sgIsComing" className="form-label">KAS TULED KAASLASEGA?</label>
                </div>
                <div className='col-2'>
                    <input className="form-check-input me-2" type="radio" name="sgIsComing" id="sgIsComing1" value="yes" onChange={handleInputChange}></input>
                    <label className="form-check-label" htmlFor="sgIsComing1">JAH</label>
                </div>
                <div className='col-2'>
                    <input className="form-check-input me-2" type="radio" name="sgIsComing" id="sgIsComing2" value="no" onChange={handleInputChange}></input>
                    <label className="form-check-label" htmlFor="sgIsComing2">EI</label>
                </div>
            </div>
            }
            </>
        )
    }

    const secondaryGuestForm = () => {
        return (
            <>
            <div className='mb-3'>
                <label htmlFor="secondaryGuest" className="form-label">SINU KAASLASE NIMI:</label>
                <input type="text" className="form-control" id="secondaryGuest" name="secondaryGuest" placeholder='Sisesta siia oma kaaslase nimi' value={regFormData.secondaryGuest} onChange={handleInputChange}></input>
            </div>
            { kuupaevaVorm('sg') }
            </>
        )
    }

    const kuupaevaVorm = (guestType: string) => {
        const days = guestType+"Days";
        return (
            <>
            { guestType === "pg" &&
            <div className="row">
                <div className='col-12'>
                    <label className="form-label">PULMATRALL KESTAB 08.07-10.07.2023. MÄRGI SIIN ÄRA, KUI PIKALT MEIEGA TÄHISTAD:</label>
                </div>
            </div>
            }
            <div className="row mb-5">
                <div className='col-6'>
                    <input className="form-check-input me-2" type="radio" name={days} id="Days" value='1' onChange={handleInputChange}></input>
                <label className="form-check-label" htmlFor="firstDay">08.07-09.07, üks öö Pilgusel</label>
            </div><div className='col-6'>
                    <input className="form-check-input me-2" type="radio" name={days} id="Days" value='2' onChange={handleInputChange}></input>
                    <label className="form-check-label" htmlFor="secondDay">08.07-10.07, kaks ööd Pilgusel</label>
                </div>
        </div>
        </>
        )
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (validateInput()) {
            const postSuccess = await PostFormData();
            if (postSuccess) {
                if (regFormData.pgIsComing === 'yes') {
                    navigate('/seeu');
                } else {
                    navigate('/bummer');
                }
            } else {
                navigate('/dang');
            }
        }
    }

    function validateInput() {
        if (regFormData.primaryGuest.length <= 2) {
            setFormError('Sõber, pane ikka oma nimi ka kirja!');
            return false;
        }
        if (regFormData.pgIsComing === '') {
            setFormError('Oot, kas sa tuled või ei?');
            return false;
        }
        if (regFormData.pgIsComing === 'yes') {
            if (regFormData.pgDays === '') {
                setFormError('Aga mitu ööd sa siis ikkagi oled?');
                return false;
            }
            if (regFormData.sgIsComing === '') {
                setFormError('Pole hullu kui kaaslast pole, panegi nii kirja');
                return false;
            }
            if (regFormData.sgIsComing === 'yes') {
                if (regFormData.secondaryGuest.length <= 2) {
                    setFormError('Kas su kaaslasel nimi ka on?');
                    return false;
                }
                if (regFormData.sgDays === '') {
                    setFormError('Viimane samm veel, ütle pikalt su kaaslane pidus on?');
                    return false;
                }
            }
        }
        return true;
    }

    async function PostFormData() {
        const apiUrl = process.env.REACT_APP_API_URL+'/api/add';
        return await axios.post(apiUrl, regFormData);
    }

    return (
      <div className="container-fluid form-container-bg w-100">
        <div className='row d-flex justify-content-center'>
            <div className='col-lg-7 col-10 form-section'>
                <form>
                    <div>
                        { primaryGuestForm() }
                    </div>
                    <div>
                        { regFormData.sgIsComing === 'yes' && secondaryGuestForm() }
                    </div>
                    <div>
                        <div className="row mt-2">
                            <div className='col-12 text-center'>
                                <label htmlFor="comments" className="form-label">MÄRKUSED, TEATED MARIALE JA SANDRILE:</label>
                            </div>
                            <div>
                            <textarea className="textarea" id="Comments" name="comments" value={regFormData.comments} rows={5} onChange={handleInputChange}></textarea>
                            </div>
                            <div className='col-12 text-center mb-3 mt-3'>
                                <label htmlFor="Submit" className="form-label">Kui oled väljad täitnud, siis kinnita seda vajutades: JAH!</label>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <div className='form-error'>{ formError }</div>
                    </div>
                    <div className='d-flex justify-content-center mt-3 mb-3'>
                        <div className='submit-button' onClick={handleSubmit}>JAH!</div>
                    </div>
                    
                </form>
            </div>
        </div>
      </div>
    );
}

export default Form;