import React from "react";
import { IfaqData } from "../../interfaces/interfaces";
import faqData from "../../data/faqData.json";
import "./../../index.css";

const faqData2: IfaqData[] = faqData;

function Faq() {
  
    const faqList = faqData2.map((item, key) =>
        <React.Fragment key={key}>    
            <div className="row ms-0 mb-3" key={item.id}>  
            <div className="page-font faq-font faq-question">{item.question}</div>
            <div className="page-font faq-font faq-answer">{item.answer}</div>
            </div>
        </React.Fragment>
    );
  
    return (
        <><div className="faq-container">
            <div className="faq-section h-100">
                <div className="row ms-2 mb-3 page-font faq-font faq-title">
                    KORDUMA KIPPUVAD KÜSIMUSED
                </div>
                {faqList}
            
            </div>
        </div></>
  );
}

export default Faq;
