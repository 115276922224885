import "./bummer.css";
import BummerImage from './../../images/bummer.png';
import { useNavigate } from "react-router-dom";

function Bummer() {

    const navigate = useNavigate();

    const backHome = () => {
        navigate('/');
    }

    return(
        <>
        <div className="container-fluid overflow-hidden">
            <div className="row pt-5 page">
                <div className="col margin pt-5">
                    <div className="row">
                        <div className="col-12 pb-3">
                            <span className="back-link" onClick={backHome}>TAGASI AVALEHELE</span>
                        </div>
                        <div className="col-12">
                            <img className="image" src={BummerImage} alt={"Dang.."}/>
                        </div>
                        <div className="col-12 bummer-font pt-3">ILMA SINUTA KADAKATES ULUB TUUL...</div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Bummer;