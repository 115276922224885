import "./notfound.css";
import NotFoundImage from './../../images/notfound.png';
import { useNavigate } from "react-router-dom";

function NotFound() {

    const navigate = useNavigate();

    const backHome = () => {
        navigate('/');
    }

    return(
        <>
        <div className="container-fluid">
            <div className="row pt-5 page">
                <div className="col margin pt-5">
                    <div className="row">
                        <div className="col-12 pb-3">
                            <span className="back-link" onClick={backHome}>TAGASI AVALEHELE</span>
                        </div>
                        <div className="col-3"></div>
                        <div className="col-6">
                            <div className="col-12">
                                <img className="image" src={NotFoundImage} alt={"Dang.."}/>
                            </div>
                            <div className="col-12 text-center mt-5 notfound-font">... SIIN POLE ENAM MIDAGI TEHA ...</div>
                        </div>
                        <div className="col-3"></div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default NotFound;