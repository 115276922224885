import axios from 'axios';
import React, { useState } from 'react';
import "./report.css";
import "../form/form.css"
import { IRsvpModel } from '../../interfaces/interfaces';

const apiUrl = process.env.REACT_APP_API_URL+'/api/list';

function Report() {
  const [rvspList, setRsvpList] = useState<IRsvpModel[]>([]);
  const [accessCode, setAccessCode] = useState('');
  const [error, setError] = useState('');

  const handleInput = (e:any) => {
    setAccessCode(e.target.value);
  }

  let rsvpData: IRsvpModel[] = [];

  const GetRsvpList = async () => {
    if (accessCode === '') {
      setError('Parool puudub, pane kõiksepealt ikka parool!');
    } else {
      const urlWIthCode = apiUrl + '?code=' + accessCode;
      const { data } = await axios.get(urlWIthCode);
      if (data) {
        rsvpData = data;
        setRsvpList(rsvpData);
        setError('');
      } else {
        setError('Oops, vale parool!');
        setAccessCode('');
      }
    }
  };

  return (
    <>
    <div className='container-fluid report-container-bg w-100'>
      <div className='row pt-5 report-font'>
      {rvspList.length === 0 &&
          <>
            <div className='row'>
              <div className='col-4'></div>
              <div className='col-4'>
                <div className="mb-5">
                    <label htmlFor="primaryGuest" className="form-label">MIS ON SALASÕNA?</label>
                    <input type="password" className="form-control" name="accessCode" id="accessCode" value={accessCode} onChange={handleInput}></input>
                    <div className='mt-3 text-center report-button rounded-1' onClick={GetRsvpList}>Vaata raportit!</div>
                </div>
              </div>
              <div className='col-4'></div>
            </div>
            <div className='row'>
              <div className='col-4'></div>
              <div className='col-4 form-error text-center'>{error}</div>
              <div className='col-4'></div>
            </div>
          </>
      }
      {rvspList.length > 0 && 
        <div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nimi</th>
                <th scope="col">Tulen</th>
                <th scope="col">Mitu Paeva</th>
                <th scope="col">Kaaslane tuleb</th>
                <th scope="col">Kaaslase nimi</th>
                <th scope="col">Mitu Paeva</th>
                <th scope="col">Komments</th>
              </tr>
            </thead>
            <tbody className='rsvp-table-font'>
            {rvspList.map((item, key) =>
              <React.Fragment key={key}>
                <tr>
                  <th scope="row">{item.id}</th>
                  <td>{item.primaryGuest}</td>
                  <td>{item.pgIsComing}</td>
                  <td>{item.pgDays}</td>
                  <td>{item.sgIsComing}</td>
                  <td>{item.secondaryGuest}</td>
                  <td>{item.sgDays}</td>
                  <td>{item.comments}</td>
                </tr>
              </React.Fragment>)}
            </tbody>
          </table>
        </div>
      }
      </div>
    </div>
    </>
  );
}

export default Report;